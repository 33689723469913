<template>
  <a-card>
    <div slot="title">{{ closephonecheck ? '添加学员' : '验证手机号' }}</div>
    <!-- 验证手机号 -->
    <a-form-model ref="phoneform" :model="phoneform" v-bind="$formItemLayout">
      <a-form-model-item label="家长联系电话：" prop="cellphone" :rules="valiPhoneRule">
        <a-input :disabled="closephonecheck" v-model.trim="phoneform.cellphone" :maxLength="11" />
      </a-form-model-item>
      <a-form-model-item
        prop="verificationCode"
        label="验证码"
        :maxLength="6"
        :rules="{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }"
        style="margin-bottom: 64px"
      >
        <a-row :gutter="[32, 0]">
          <a-col span="12"><a-input :disabled="closephonecheck" v-model.trim="phoneform.verificationCode"/></a-col>
          <a-col span="3" v-if="!closephonecheck"
            ><a-button type="primary" ghost shape="round" style="width:160px" @click="getPhoneCode">
              {{ codetext }}
            </a-button></a-col
          >
        </a-row>
      </a-form-model-item>
      <a-row v-if="!closephonecheck">
        <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
          <a-button type="primary" shape="round" style="width:160px" @click="goNext">
            下一步
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>

    <!-- 填写信息 -->
    <a-form-model v-if="closephonecheck" ref="form" :model="form" v-bind="$formItemLayout">
      <a-form-model-item label="儿童姓名：" prop="username" :rules="valiNameRule">
        <a-input v-model.trim="form.username" :maxLength="25" />
      </a-form-model-item>
      <a-form-model-item label="儿童昵称：" prop="nickname" :rules="valiNameRule">
        <a-input v-model.trim="form.nickname" :maxLength="25" />
      </a-form-model-item>
      <a-form-model-item
        label="儿童性别："
        :rules="{
          required: true,
          message: '请选择',
          trigger: 'change'
        }"
        prop="sex"
      >
        <a-radio-group v-model="form.sex">
          <a-radio v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
            {{ statusInfo.label }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        label="儿童出生日期："
        prop="birthday"
        :rules="{
          required: true,
          message: '请选择',
          trigger: 'change'
        }"
      >
        <a-date-picker
          :disabled-date="disabledDate"
          v-model="form.birthday"
          valueFormat="YYYY-MM-DD"
          style="flex:1;width: 100%"
        />
      </a-form-model-item>

      <a-form-model-item label="监护人：" prop="guardians" :rules="valiNameRule">
        <a-input v-model.trim="form.guardians" />
      </a-form-model-item>
      <a-form-model-item
        label="所属机构："
        prop="mechanismId"
        :rules="{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }"
        style="margin-bottom: 64px"
      >
        <h-cascader
          :options="mechanismOptions"
          :value="defaultMechanism"
          change-on-select
          @change="onChange"
          :fieldNames="{ label: 'name', value: 'id', children: 'childrenList' }"
          placeholder="请选择"
        >
        </h-cascader>
      </a-form-model-item>
      <a-row>
        <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
          <a-button type="primary" shape="round" style="width:160px" @click="onSubmit" :loading="loading">
            确认
          </a-button>
        </a-col>
      </a-row>
    </a-form-model></a-card
  >
</template>

<script>
import * as Api from '@/api/student'
import events from '@/components/MultiTab/events'
import { GENDER_TYPE } from '@/store/dictionary'
import { cloneDeep } from 'lodash'
import * as mechanismApi from '@/api/mechanism'
import { sendCode, verifCode } from '@/api/login'
import moment from 'moment'

export default {
  name: 'StudentManagementAdd',
  data() {
    const MobileNumberValidator = (rule, value, callback) => {
      const idcardReg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (!idcardReg.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入正确的手机号码')
      }
      callback()
    }

    const nameValidator = (rule, value, callback) => {
      const idNameReg = /^[\u4e00-\u9fa5a-zA-Z]+$/
      if (!idNameReg.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('只能输入字母和汉字')
      }
      callback()
    }

    return {
      valiNameRule: [
        { validator: nameValidator, max: 11, trigger: ['blur'] },
        {
          required: true,
          message: '请输入',
          trigger: 'blur'
        }
      ],
      valiPhoneRule: [
        { validator: MobileNumberValidator, max: 11, trigger: ['blur'] },
        {
          required: true,
          message: '请输入',
          trigger: ['blur']
        }
      ],
      form: {
        username: '', // 姓名
        nickname: '', // 昵称
        sex: '', // 性别
        birthday: '', // 生日
        cellphone: '', // 电话
        guardians: '', // 监护人
        mechanismId: '' // 机构id
      },
      statusOptions: GENDER_TYPE, // 性别列表
      mechanismOptions: [], // 机构列表
      defaultMechanism: [], // 选中的机构
      phoneform: {
        cellphone: '', // 验证电话
        verificationCode: '' // 验证码
      },
      codetext: '获取验证码',
      canClick: true,
      timer: null,
      loading: false, // 确认按钮loading
      closephonecheck: false // 关闭验证手机号
    }
  },
  mounted() {
    // 获取结构列表
    mechanismApi.mechanismOptions().then(res => {
      this.mechanismOptions = res.data
    })
  },
  methods: {
    valiPhone() {
      const {
        phoneform: { cellphone }
      } = this
      const reg = /^1[23456789]\d{9}$/g
      let result = true
      if (cellphone === '') {
        this.$notification.error({
          message: '提示',
          description: '请输入手机号'
        })
        result = false
        return
      }
      if (!reg.test(cellphone)) {
        this.$notification.error({
          message: '提示',
          description: '请确认手机号是否正确'
        })
        result = false
        return
      }
      return result
    },
    valiCode() {
      const {
        phoneform: { verificationCode }
      } = this
      let result = true
      if (verificationCode === '') {
        this.$notification.error({
          message: '提示',
          description: '请输入验证码'
        })
        result = false
      }
      return result
    },
    // 获取验证码
    getPhoneCode() {
      const {
        phoneform: { cellphone }
      } = this

      if (this.valiPhone()) {
        // 检查手机号是否存在
        Api.checkPhone({ cellphone }).then(res => {
          if (!res.data) {
            // 获取验证码
            if (this.canClick) {
              sendCode({ cellphone }).then(res => {
                if (res.code === 0) {
                  this.canClick = false
                  let count = 300
                  this.timer = setInterval(() => {
                    count--
                    this.codetext = count + 's'
                    if (count <= 0) {
                      clearInterval(this.timer)
                      this.canClick = true
                      return (this.codetext = '发送验证码')
                    }
                  }, 1000)
                  this.$notification.success({
                    message: '提示',
                    description: '验证码发送成功，请注意查收'
                  })
                }
              })
            }
          } else {
            this.$notification.error({
              message: '错误',
              description: '手机号已存在，请更换手机号后再次尝试'
            })
          }
        })
      }
    },
    goNext() {
      const { phoneform } = this
      if (this.valiPhone() && this.valiCode()) {
        verifCode(phoneform).then(res => {
          if (res.code === 0) {
            // 关闭手机验证
            this.closephonecheck = true
            clearInterval(this.timer)
            this.codetext = '获取验证码'
            this.canClick = true
            this.timer = null
          } else {
            this.$notification.error({
              message: '错误',
              description: '验证码校验失败，请重新输入'
            })
          }
        })
      }
    },
    onSubmit() {
      const {
        formatForm,
        validate,
        $notification,
        phoneform: { cellphone }
      } = this

      validate().then(valid => {
        if (valid) {
          this.loading = true
          try {
            const form = formatForm()
            form.cellphone = cellphone
            Api.create({ ...form })
              .then(res => {
                const { code, msg } = res
                if (code === 0) {
                  $notification['success']({ message: '操作成功' })
                  this.close()
                } else {
                  $notification['error']({ message: '提示', description: `${msg}` })
                }
              })
              .finally(() => (this.loading = false))
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    // 格式化
    formatForm() {
      try {
        const ret = cloneDeep(this.form)
        return { ...ret }
      } catch {}
    },
    // 验证
    validate() {
      const {
        $refs: { form }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    // 选择中机构
    onChange(record) {
      this.defaultMechanism = record
      this.$set(this.form, 'mechanismId', record[record.length - 1])
    },
    close() {
      events.$emit('close')
      this.$router.go(-1)
    },
    disabledDate(current) {
      // Can not select days before today and today
      return (
        current >
        moment()
          .subtract(1, 'days')
          .endOf('day')
      )
    }
  }
}
</script>

<style lang="less" scoped></style>
