var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.closephonecheck ? '添加学员' : '验证手机号'))]),_c('a-form-model',_vm._b({ref:"phoneform",attrs:{"model":_vm.phoneform}},'a-form-model',_vm.$formItemLayout,false),[_c('a-form-model-item',{attrs:{"label":"家长联系电话：","prop":"cellphone","rules":_vm.valiPhoneRule}},[_c('a-input',{attrs:{"disabled":_vm.closephonecheck,"maxLength":11},model:{value:(_vm.phoneform.cellphone),callback:function ($$v) {_vm.$set(_vm.phoneform, "cellphone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"phoneform.cellphone"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"64px"},attrs:{"prop":"verificationCode","label":"验证码","maxLength":6,"rules":{
        required: true,
        message: '请输入验证码',
        trigger: 'blur'
      }}},[_c('a-row',{attrs:{"gutter":[32, 0]}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-input',{attrs:{"disabled":_vm.closephonecheck},model:{value:(_vm.phoneform.verificationCode),callback:function ($$v) {_vm.$set(_vm.phoneform, "verificationCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"phoneform.verificationCode"}})],1),(!_vm.closephonecheck)?_c('a-col',{attrs:{"span":"3"}},[_c('a-button',{staticStyle:{"width":"160px"},attrs:{"type":"primary","ghost":"","shape":"round"},on:{"click":_vm.getPhoneCode}},[_vm._v(" "+_vm._s(_vm.codetext)+" ")])],1):_vm._e()],1)],1),(!_vm.closephonecheck)?_c('a-row',[_c('a-col',_vm._b({},'a-col',_vm.$formItemLayoutWithoutLabel.wrapperCol,false),[_c('a-button',{staticStyle:{"width":"160px"},attrs:{"type":"primary","shape":"round"},on:{"click":_vm.goNext}},[_vm._v(" 下一步 ")])],1)],1):_vm._e()],1),(_vm.closephonecheck)?_c('a-form-model',_vm._b({ref:"form",attrs:{"model":_vm.form}},'a-form-model',_vm.$formItemLayout,false),[_c('a-form-model-item',{attrs:{"label":"儿童姓名：","prop":"username","rules":_vm.valiNameRule}},[_c('a-input',{attrs:{"maxLength":25},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.username"}})],1),_c('a-form-model-item',{attrs:{"label":"儿童昵称：","prop":"nickname","rules":_vm.valiNameRule}},[_c('a-input',{attrs:{"maxLength":25},model:{value:(_vm.form.nickname),callback:function ($$v) {_vm.$set(_vm.form, "nickname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.nickname"}})],1),_c('a-form-model-item',{attrs:{"label":"儿童性别：","rules":{
        required: true,
        message: '请选择',
        trigger: 'change'
      },"prop":"sex"}},[_c('a-radio-group',{model:{value:(_vm.form.sex),callback:function ($$v) {_vm.$set(_vm.form, "sex", $$v)},expression:"form.sex"}},_vm._l((_vm.statusOptions),function(statusInfo){return _c('a-radio',{key:statusInfo.value,attrs:{"value":statusInfo.value}},[_vm._v(" "+_vm._s(statusInfo.label)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"label":"儿童出生日期：","prop":"birthday","rules":{
        required: true,
        message: '请选择',
        trigger: 'change'
      }}},[_c('a-date-picker',{staticStyle:{"flex":"1","width":"100%"},attrs:{"disabled-date":_vm.disabledDate,"valueFormat":"YYYY-MM-DD"},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}})],1),_c('a-form-model-item',{attrs:{"label":"监护人：","prop":"guardians","rules":_vm.valiNameRule}},[_c('a-input',{model:{value:(_vm.form.guardians),callback:function ($$v) {_vm.$set(_vm.form, "guardians", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.guardians"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"64px"},attrs:{"label":"所属机构：","prop":"mechanismId","rules":{
        required: true,
        message: '请选择',
        trigger: 'blur'
      }}},[_c('h-cascader',{attrs:{"options":_vm.mechanismOptions,"value":_vm.defaultMechanism,"change-on-select":"","fieldNames":{ label: 'name', value: 'id', children: 'childrenList' },"placeholder":"请选择"},on:{"change":_vm.onChange}})],1),_c('a-row',[_c('a-col',_vm._b({},'a-col',_vm.$formItemLayoutWithoutLabel.wrapperCol,false),[_c('a-button',{staticStyle:{"width":"160px"},attrs:{"type":"primary","shape":"round","loading":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" 确认 ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }